﻿.contact-content {
	background: rgba($color-secondary, 0.15);
	padding: 4.75rem 3.125rem 4.5rem;

	&__sub-title {
		@include font-sub-title;
		text-transform: uppercase;
		font-weight: 900;
		margin: 3.25rem 0 0.188rem;
	}
}

.google-maps__wrapper {
	margin: 5rem 0 0;
}