﻿.video-link {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	z-index: 1;
	text-align: center;

	&:after {
		@include icon($icon-play);
		position: absolute;
		width: 4.688rem;
		height: 4.688rem;
		padding: 1.125rem 0 0 0.375rem;
		border-radius: 50%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background: $color-secondary;
		font-size: 2.313rem;
		color: #fff;
		transition: background 0.3s ease-in-out;
	}

	&:hover {
		&:after {
			background: $color-primary;
		}
	}
}
