﻿.notification {
	@include font-legal;
	z-index: 6;
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	background: $color-banana;

	.notification__wrapper {
		width: 100%;
		color: $color-primary;
		padding: rem(10px);
		box-sizing: border-box;

		p {
			margin: 0 0 rem(10px);

			&:last-child {
				margin: 0;
			}
		}
	}

	.button {
		margin-bottom: 20px;
	}

	.notification__close {
		position: absolute;
		display: block;
		width: rem(16px);
		height: rem(16px);
		top: rem(10px);
		right: rem(10px);
		text-indent: -9999px;
		cursor: pointer;

		&:after {
			@include icon($icon-close);
			position: absolute;
			top: 0;
			left: 0;
			font-size: rem(16px);
			color: $color-primary;
			text-indent: 0;
		}
	}
}
