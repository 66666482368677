﻿.languages {
	width: 100%;
	padding: rem(15px) rem(0) rem(115px);
	margin: 0 auto rem(80px);

	@include bp-min(m) {
		padding: rem(15px) rem(20px);
	}

	&__list {
		padding: 0;
		margin: 0 rem(-20px);
		list-style: none;
		overflow: hidden;
	}

	dt {
		position: relative;
		text-transform: uppercase;
		color: #fff;
		text-align: right;
		border-top: 1px solid $color-secondary-border;
		border-bottom: 1px solid $color-secondary-border;
		padding: 0.438rem 2.125rem 0.25rem 0.875rem;
		cursor: pointer;

		&:after {
			@include icon($icon-arrow-down);
			position: absolute;
			top: 1rem;
			right: 0.625rem;
			font-size: 0.563rem;
		}

		&:before {
			content: attr(data-responsive-text);
			float: left;
			text-align: left;
			text-transform: none;
		}
	}

	dd {
		margin: 0;
		padding: 0;
		visibility: hidden;
		display: none;
		opacity: 0;
	}

	ul {
		margin: 0;
	}

	&:hover,
	&.open {

		dd {
			visibility: visible;
			display: block;
			opacity: 1;
		}

		dt:after {
			@include icon($icon-arrow-up);
		}
	}

	.collapse {
		height: 0;
	}

	li {
		width: 100%;

		a {
			position: relative;
			display: block;
			padding: 0.438rem 0.875rem 0.25rem;
			color: #fff;
			border-bottom: 1px solid $color-secondary-border;

			@include bp-min(m) {
				border-bottom: none;
			}

			&:hover {
				color: $color-secondary;
			}

			&.-active {
				background: #fff;
				color: $color-primary;
			}
		}
	}

	@include bp-min(m) {
		float: right;
		position: absolute;
		z-index: 1;
		padding: 0;
		top: 3.25rem;
		right: 0;
		width: auto;
		padding: 0;
		margin: 0;

		&:hover,
		&.open {
			background: $color-menu;
		}

		dt {
			display: block;
			padding: 0.938rem 1.5rem 0.938rem 0.875rem;
			cursor: pointer;
			text-align: left;
			border-top: none;
			border-bottom: none;

			.open &,
			&:hover {
				background: $color-menu;
			}


			&:after {
				top: 1.438rem;
			}

			&:before {
				display: none;
			}
		}

		dd {
			position: absolute;
			right: 0;
		}

		&__list {
			list-style: none;
			background: $color-menu;
			padding: 0;
			margin: 0;

			li {
				position: relative;
				float: left;
				clear: both;
				width: 100%;

				a {
					@include font-navigation;
					display: block;
					padding: 0.75rem 1rem;

					&:hover {
						background: #fff;
						color: $color-primary;
					}
				}
			}
		}
	}

	@include bp-min(xl) {
		right: -0.875rem;
	}
}
