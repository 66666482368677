﻿.employee {
	background: rgba($color-secondary, 0.15);
	height: 100%;

	&__content {
		padding: 3.125rem 1.25rem 2.313rem;

		@include bp-min(m) {
			padding: 3.125rem 3.125rem 2.313rem;
		}

		h3,
		h4 {
			text-transform: uppercase;
			font-weight: 900;
			margin: 0 0 1rem;
		}

		h3 {
			color: $color-primary;
			font-size: 1.5rem;
		}

		h4 {
			font-size: 1.25rem;
		}

		.contact-list,
		.buttons {
			margin: 1rem 0;
		}
	}
}
