﻿.breadcrumbs {
	@include font-small;
	position: relative;
	color: $color-secondary;

	&__wrapper {
		padding: 0.375rem 0;
	}

	&,
	ul {
		@extend .no-bullets;
		@extend .clearfix;
		margin: 0;
	}

	li,
	ul,
	a {
		position: relative;
		display: block;
		float: left;
	}

	li + li {
		padding-left: 1.75rem;

		&:before {
			@include icon($icon-arrow-right);
			position: absolute;
			top: 1rem;
			left: 0.625rem;
			font-size: 0.5rem;
		}
	}

	li {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	a {
		text-decoration: none;


		&:hover {
			text-decoration: underline;
		}
	}
}
