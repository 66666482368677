﻿.footer-content {
	color: #fff;
	padding: 1rem 0 0.875rem;

	&.-contact {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: 15rem;
		margin: 0 auto;

		.footer-content__title {
			max-width: 12.5rem
		}
	}

	&__title {
		@include font-footer;
		//color: #fff;
		max-width: 17.5rem;
		margin: 0 0 1.063rem;
		text-transform: uppercase;
		text-align: left;
	}

	&__button {
		margin: 0 0 1.688rem;
	}

	&__slogan {
		display: block;
		@include font-sub-title;
		text-align: center;
		text-transform: uppercase;
		font-weight: 900;
	}
}
