﻿.info-window {
	@include font-text;
	padding: 0.625rem 0;

	h2 {
		@include font-footer;
		text-transform: uppercase;
		font-weight: 900;
		color: $color-primary;
		margin: 0 0 0.313rem;
		max-width: 18rem;
	}

	span {
		display: block;
		margin: 0 0 0.188rem;
	}

	.button {
		margin: 0.563rem 0 0;
	}
}
