﻿.paging {
	@extend .clearfix;
	margin: -0.625rem 0 0.063rem;
	pointer-events: none;

	&:last-child {
		margin: -3.75rem 0 1.25rem;
	}

	ul {
		@extend .clearfix;
		@extend .no-bullets;
		float: right;
		margin: -0.313rem;
	}

	li {
		float: left;
	}

	a {
		float: left;
		display: block;
		position: relative;
		width: 2.5rem;
		height: 2.5rem;
		margin: 0.313rem;
		border-radius: 50%;
		background: rgba($color-secondary, 0.15);
		text-indent: -9999px;
		transition: background 0.3s ease-in-out;
		pointer-events: initial;

		&:hover {
			background: $color-primary;

			&:before {
				color: #fff;
			}
		}
	}

	.overview,
	.previous,
	.next {

		&:before {
			position: absolute;
			color: $color-primary;
			text-indent: 0;
			transition: color 0.3s ease-in-out;
		}
	}

	.overview {

		&:before {
			@include icon($icon-overview);
			font-size: 1.125rem;
			top: 0.688rem;
			left: 0.688rem;
		}
	}

	.previous,
	.next {

		&:before {
			font-size: 1.25rem;
			top: 0.625rem;
		}
	}

	.previous {

		&:before {
			@include icon($icon-arrow-left);
			right: 0.75rem;
		}
	}

	.next {

		&:before {
			@include icon($icon-arrow-right);
			left: 0.75rem;
		}
	}
}
