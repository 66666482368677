﻿.home-intro {
	position: relative;
	z-index: 0;
	color: #fff;
	text-align: center;
	background: $color-primary;
	padding: 4.688rem 1.25rem 3.25rem;

	@include bp-min(xs) {
		padding: 4.688rem 3.125rem 3.25rem;
	}

	&__background-image {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	.text {
		max-width: 71.25rem;
		margin-right: auto;
		margin-left: auto;
	}

	&__image {
		margin-bottom: 3.125rem;

		@include bp-min(m) {
			margin-bottom: 0;
		}
	}
}
