﻿.anchor {
	position: absolute;
	top: 0;

	@include bp-min(m) {
		top: rem(-120px);
	}

	&.-bottom {
		top: auto;
		bottom: 0;
	}
}
