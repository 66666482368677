﻿.project-info {
	background: rgba($color-secondary, 0.15);
	padding: 1.438rem 3.125rem 1.375rem;
	margin: 0 0 3.125rem;
	font-weight: bold;
	text-transform: uppercase;
	color: $color-primary;

	&__info {
		margin: 0;
	}

	&__link {
		color: $color-secondary;

		&:after {
			@include icon($icon-arrow-right);
			font-size: 0.625rem;
			margin-left: 0.375rem;
			transition: margin-left 0.3s ease-in-out;
		}

		&:hover:after {
			margin-left: 0.5rem;
		}
	}
}
