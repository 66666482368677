﻿$icon-arrow-bold-down: "\ea01";
$icon-arrow-bold-left: "\ea02";
$icon-arrow-bold-right: "\ea03";
$icon-arrow-bold-up: "\ea04";
$icon-arrow-down: "\ea05";
$icon-arrow-left: "\ea06";
$icon-arrow-right: "\ea07";
$icon-arrow-up: "\ea08";
$icon-arrow-xbold-down: "\ea09";
$icon-calender: "\ea0a";
$icon-check: "\ea0b";
$icon-close: "\ea0c";
$icon-facebook: "\ea0d";
$icon-instagram: "\ea0e";
$icon-linkedin: "\ea0f";
$icon-magazine: "\ea10";
$icon-mail: "\ea11";
$icon-overview: "\ea12";
$icon-pinterest: "\ea13";
$icon-play: "\ea14";
$icon-telephone: "\ea15";
$icon-twitter: "\ea16";
$icon-youtube: "\ea17";

@at-root {
    @font-face {
        font-family: icon;
        
        src: url("../../fonts/icon.woff2") format("woff2"), url("../../fonts/icon.woff") format("woff"); 
        font-style: normal;
        font-weight: normal;
    }
}

@mixin icon($content: none) {
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-family: icon;
    line-height: 1;
    text-transform: none;
    speak: none;
    content: $content;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon:before {
    @include icon;
}

.icon-arrow-bold-down:before { content: $icon-arrow-bold-down; }
.icon-arrow-bold-left:before { content: $icon-arrow-bold-left; }
.icon-arrow-bold-right:before { content: $icon-arrow-bold-right; }
.icon-arrow-bold-up:before { content: $icon-arrow-bold-up; }
.icon-arrow-down:before { content: $icon-arrow-down; }
.icon-arrow-left:before { content: $icon-arrow-left; }
.icon-arrow-right:before { content: $icon-arrow-right; }
.icon-arrow-up:before { content: $icon-arrow-up; }
.icon-arrow-xbold-down:before { content: $icon-arrow-xbold-down; }
.icon-calender:before { content: $icon-calender; }
.icon-check:before { content: $icon-check; }
.icon-close:before { content: $icon-close; }
.icon-facebook:before { content: $icon-facebook; }
.icon-instagram:before { content: $icon-instagram; }
.icon-linkedin:before { content: $icon-linkedin; }
.icon-magazine:before { content: $icon-magazine; }
.icon-mail:before { content: $icon-mail; }
.icon-overview:before { content: $icon-overview; }
.icon-pinterest:before { content: $icon-pinterest; }
.icon-play:before { content: $icon-play; }
.icon-telephone:before { content: $icon-telephone; }
.icon-twitter:before { content: $icon-twitter; }
.icon-youtube:before { content: $icon-youtube; }
