﻿.image {
	width: 100%;
	max-width: initial;

	&__container {
		@include responsive-container-psudo(600, 400);
		overflow: hidden;
		width: 100%;
		margin: 1.25rem 0 2rem;

		@include bp-min(s) {
			float: right;
			max-width: 26.625rem;
			margin: 0.25rem 0 1.75rem 3.125rem;
		}

		@include bp-min(m) {
			max-width: rem(600px);
		}

		&.-full-width {
			@include responsive-container-psudo(1378, 450);
			float: none;
			max-width: initial;
			margin: 2rem 0 2rem;
		}

		&.-small {

			@include bp-min(s) {
				max-width: 26.625rem;
			}
		}
	}
}
