﻿.link {
	position: relative;
	display: inline-block;
	padding: 0 3rem 0 0;
	color: $color-secondary;

	&:after {
		@include icon($icon-arrow-bold-right);
		position: absolute;
		margin: -0.375rem 0 0;
		top: 50%;
		right: 1.25rem;
		font-size: 0.75rem;
		transition: right 0.3s ease-in-out;
	}

	&:hover:after {
		right: 1rem;
	}
}

.links {

	.content-block & {
		margin-top: 1.625rem;
		margin-bottom: 1.313rem;
	}

	a {
		@extend .link;
	}
}