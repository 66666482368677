﻿.contact-box {
	@extend .clearfix;
	position: fixed;
	z-index: 3;
	height: 3.125rem;
	width: 9.375rem;
	margin: 0 0 0 -4.688rem;
	bottom: 2rem;
	left: 50%;
	pointer-events: none;

	@include bp-min(m) {
		height: auto;
		width: 17rem;
		margin: 0;
		top: 19.375rem;
		right: 0;
		bottom: auto;
		left: auto;
		display: flex;
		flex-direction: column;
	}

	&__item {
		position: relative;
		float: left;
		height: 3.125rem;
		width: 3.125rem;
		color: #fff;
		//left: rem(55px);
		transition: right 0.3s ease-in-out;
		text-indent: -9999px;
		pointer-events: initial;

		@include bp-min(m) {
			float: none;
			height: 3.125rem;
			float: right;
			width: 100%;
			right: -100%;
			left: auto;
			text-indent: 0;
			clear: both;
			padding: rem(14px) rem(20px) 0;
		}

		a {
			display: block;
			color: #fff;
			text-transform: none;
			//padding: 0.813rem 0 0 0;
		}

		&.-telephone {
			background: $color-primary;

			&:before {
				@include icon($icon-telephone);
				//font-size: rem(24px);
				background: $color-primary;
				padding-top: 0.813rem;
				//margin-right: 0;
			}

			a:before {
				@include icon($icon-telephone);
			}
		}

		&.-email {
			background: $color-secondary;
			margin-right: rem(-97px);
			//left: rem(120px);
			&:before {
				@include icon($icon-mail);
				background: $color-secondary;
				padding-top: 0.875rem;
			}

			a:before {
				@include icon($icon-mail);
			}
		}

		&.-magazine {
			background: $color-primary;

			&:before {
				@include icon($icon-magazine);
				padding-top: 0.875rem;
				background: $color-primary;
			}

			a:before {
				@include icon($icon-magazine);
			}
		}

		&:before {
			position: absolute;
			display: none;
			content: '';
			width: 3.125rem;
			height: 3.125rem;
			top: 0;
			left: -3.125rem;
			text-align: center;
			font-size: 1.313rem;

			@include bp-min(m) {
				display: block;
			}
		}

		&:hover {

			@include bp-min(m) {
				right: 0;
			}
		}

		a {

			&:before {
				position: absolute;
				font-size: rem(24px);
				top: 50%;
				left: 50%;
				text-indent: 0;
				transform: translate(-50%, -50%);

				@include bp-min(m) {
					display: none;
				}
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
