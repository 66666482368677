﻿.pylot {

	.container {
		z-index: 0;
		max-width: 92.375rem;
		padding: 0 1.25rem;

		@include bp-min(s) {
			padding: 0 3.125rem;
		}

		@include bp-min(m) {
			padding: 3.125rem;
		}
	}
}

.pylot-logo {
	position: absolute;
	background: url(../../images/logo-pylot.svg);
	width: 12.5rem;
	height: 4.1875rem;
	top: 3rem;
	left: 3rem;
	text-indent: -9999px;
	overflow: hidden;

	@include bp-min(s) {
		width: 18.75rem;
		height: 6.3125rem;
		top: 4rem;
		left: 4rem;
	}

	@include bp-min(m) {
		top: 3.125rem;
		left: 3.125rem;
	}
}

.pylot-content {
	background: #fff;
	width: 100%;
	padding: 2.8125rem 0 3.125rem;

	@include bp-min(s) {
		padding: 2.8125rem 3.125rem 3.125rem 4.75rem;
	}

	@include bp-min(m) {
		float: right;
		width: 53%;
	}
}

.pylot-background-image {
	width: 100%;
	height: 400px;
	top: 0;
	left: 0;
	object-fit: cover;
	object-position: left bottom;

	@include bp-min(m) {
		z-index: -1;
		position: absolute;
		height: 100%;
	}
}