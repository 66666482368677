﻿.layout {
	margin: 0 0 0.875rem;
	overflow: hidden;

	&__content {

		@include bp-min(m) {
			float: left;
			width: calc(100% - 29.75rem);
		}
	}

	&__aside {

		@include bp-min(m) {
			float: left;
			width: 29.75rem;
			padding: 0 0 0 3.125rem;
		}
	}
}
