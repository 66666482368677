﻿.anniversary-slider {

	.swiper-slide:nth-child(odd) .anniversary-slider-item {
		background: $color-secondary-contrast;

		&:before {
			border-color: transparent transparent transparent mix($color-secondary, #fff, 15%);
		}
	}

	.swiper-slide.swiper-slide-active .anniversary-slider-item {

		&:before {
			opacity: 0;
		}
	}
}

.anniversary-slider-item {
	position: relative;
	background: mix($color-secondary, #fff, 15%);
	padding: rem(45px) rem(50px) rem(43px);
	text-align: left;

	&__date {
		@include font-footer;
		font-weight: 900;
		text-transform: uppercase;
		color: $color-secondary;
	}

	.title {
		font-size: 1.875rem;
		line-height: 2.5rem;
	}

	&:before {
		position: absolute;
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: rem(20px) 0 rem(20px) rem(20px);
		border-color: transparent transparent transparent $color-secondary-contrast;
		top: rem(40px);
		left: 0;
		opacity: 1;
		transition: opacity 1.5s ease-in-out;
	}
}