﻿.hero {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: 100vh;
	max-height: 25rem;
	color: #fff;
	padding: 10rem 0 3rem;

	@include bp-min(m) {
		max-height: 37.5rem;
	}

	&.-small {
		min-height: initial;
		height: 11.25rem;
		padding: 0;

		&:after {
			position: absolute;
			content: '';
			width: 100%;
			height: 10rem;
			top: 0;
			left: 0;
			background: linear-gradient(to bottom, rgba(0,59,106,1) 0%,rgba(0,59,106,0) 100%);
		}
	}

	&.-medium {
		height: auto;
		min-height: 11.25rem;

		@include bp-min(m) {
			@include responsive-container-psudo(2560, 460);
			padding: 0;
		}

		&:after {
			position: absolute;
			content: '';
			width: 100%;
			height: 12.5rem;
			top: 0;
			left: 0;
			background: linear-gradient(to bottom, rgba(0,59,106,1) 0%,rgba(0,59,106,0) 100%);
		}
	}

	&.-full-height {
		max-height: initial;
		min-height: rem(540px);

		.hero__title {
			margin-top: rem(-100px);
		}

		@include bp-min(m) {
			margin-top: 0;
		}
	}

	&.-kubo-75 {

		&:after {
			position: absolute;
			content: '';
			z-index: 1;
			width: rem(221px);
			height: rem(184px);
			right: rem(9px);
			bottom: rem(9px);
			background: url(../../images/kubo-75.png);
		}
	}

	&__title-container {
		z-index: 1;
		width: 100%;
		padding: 2rem;
		align-self: center;
		box-sizing: border-box;
	}

	&__title {
		@include font-hero;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		//max-width: 33rem;
		margin: 0 auto 2rem;
		display: none;

		&--visible {
			display: block;
		}

		[lang="zh"] & {
			letter-spacing: 0;
			line-height: 3rem;

			@include bp-min(s) {
				line-height: 4rem;
			}

			@include bp-min(m) {
				line-height: 4.5rem;
			}
		}

		p {
			margin: 0;
		}

		.frontpage & {
			display: block;
		}

		.-medium & {
			@include font-hero-small;
			text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
		}

		@include bp-min(s) {
			margin: 0 auto 2.5rem;
		}

		@include bp-min(m) {
			margin: 0 auto 3rem;
		}
	}

	&__text {
		margin: 0 auto;
		max-width: 41.5rem;
		text-align: center;
	}

	&__image {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: top center;
	}

	&__scroll {
		position: absolute;
		display: none;
		width: 3.125rem;
		height: 4.125rem;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		text-indent: -9999px;
		overflow: hidden;

		&:after {
			@include icon($icon-arrow-down);
			position: absolute;
			top: 0;
			left: 0;
			font-size: 2.938rem;
			text-indent: 0;
		}

		@include bp-min(xs) {
			display: block;
		}
	}

	&__button {
		position: absolute;
		z-index: 100;
		right: rem(40px);
		bottom: rem(160px);
	}
}
