﻿.legal {
	@include font-legal;
	position: relative;
	padding: 0.625rem 0 5.5rem;
	margin: 0 auto;
	overflow: hidden;
	border-top: 1px solid rgba(#fff, 0.1);
	color: $color-secondary;

	@include bp-min(m) {
		padding: 0.625rem 0 4.188rem;
	}

	&__list {
		@extend .clearfix;
		@extend .no-bullets;
		position: relative;
		margin: 0;
		padding: 0;
	}

	&__item {
		float: left;
		margin-right: 1.625rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		text-transform: none;
	}

	a {

		&:hover {
			text-decoration: underline;
		}
	}
}