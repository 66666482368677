﻿.checkbox,
.dataconsent {
	position: relative;
	text-align: left;

	input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;

		&:checked + label {

			&:before {
				@include icon($icon-check);
				border-color: rgba($color-primary, 0.3);
				color: $color-primary;
			}
		}
	}

	label {
		position: relative;
		padding: 0 0 0 2.5rem;
		margin: 0 0 1.625rem;
		cursor: pointer;

		&:before {
			position: absolute;
			content: '';
			width: 1.25rem;
			height: 1.25rem;
			padding: 0.25rem 0 0;
			top: 0.125rem;
			left: 0;
			font-size: 13px;
			text-align: center;
			background: #fff;
		}

		&:hover:before {
			color: rgba($color-primary, 0.5);
		}

		p {
			margin: 0;
		}

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}
