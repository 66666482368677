﻿$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 26px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 5px;
$hamburger-layer-color: #1d1d1b;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 0.3s;
$hamburger-hover-transition-timing-function: ease-in-out;

@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
	display: block;
	z-index: 1001;
	position: fixed;
	width: 60px;
	height: 60px;
	padding: 0 10px;
	top: 17px;
	right: 40px;
	cursor: pointer;
	background: #fff;
	color: #1d1d1b;
	opacity: 1;
	transition: all 0.3s ease-in-out;
	outline: none !important;
	border-radius: 50%;

	@include bp-min(m) {
		display: none;
	}

	.hamburger-box {
		position: static;
	}

	&:hover,
	&:focus,
	&:active {
		background: $color-secondary;
	}

	&:after {
		display: none;
	}
}