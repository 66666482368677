﻿.social-media {
	@extend .clearfix;

	+ .title {
		margin-top: 4.125rem;
	}

	dt {
		footer & {
			display: none;
		}
	}

	&__list {
		overflow: hidden;
		list-style: none;
		width: 100%;
		padding: 0;
		margin: 0 0;
	}

	&__item {
		display: inline-block;
		padding: 0 0.313rem;
		margin: 0;
	}

	&__link {
		position: relative;
		display: block;
		overflow: hidden;
		width: 1.25rem;
		height: 1.25rem;
		text-indent: 9999px;
		transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
		color: $color-secondary;

		footer & {
			color: #fff;
		}

		&:after {
			font-size: 1.25rem;
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 1;
			transform: translate(-50%, -50%);
			text-indent: 0;
			transition: transform 0.2s ease-in-out;
		}

		&.-facebook:after {
			@include icon($icon-facebook);
		}

		&.-youtube:after {
			@include icon($icon-youtube);
		}

		&.-twitter:after {
			@include icon($icon-twitter);
		}

		&.-linkedin:after {
			//@include icon($icon-linkedin);
		}

		&.-google-plus:after {
			//@include icon($icon-google-plus);
		}

		&.-soundcloud:after {
			//@include icon($icon-soundcloud);
		}

		&.-vimeo:after {
			//@include icon($icon-vimeo);
		}

		&.-instagram:after {
			@include icon($icon-instagram);
		}

		&.-flickr:after {
			//@include icon($icon-flickr);
		}

		&.-pinterest:after {
			//@include icon($icon-pinterest);
		}

		&:hover {
			color: $color-primary;

			footer & {
				color: $color-secondary;
			}
		}
	}
}
