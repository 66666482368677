﻿.contact-list {
	overflow: hidden;
	margin: 0 0 1.625rem 0;

	dt {
		float: left;
		width: 9rem;
		padding: 0 0.313rem 0 0;

		&.icon {
			width: 2.5rem;
			margin-bottom: 1.25rem;
			font-size: 1.125rem;
			color: $color-primary;

			+ dd {
				width: calc(100% - 2.5rem);
			}
		}

		+ dd {
			width: calc(100% - 9rem);
			margin-bottom: 1.25rem;
		}
	}

	dd {
		float: left;
		width: calc(100% - 9rem);
		margin: 0;
	}

	p {
		margin: 0;
	}

	&__link {
		color: $color-secondary;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}
