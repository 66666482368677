﻿.project-label-slide {
	box-sizing: border-box;

	.no-js & {
		width: 100%;
		padding: 0 1.563rem;

		@include bp-min(s) {
			width: 50%;
		}

		@include bp-min(m) {
			width: (100% / 3);
		}
	}

	&__content {
		padding: 2.5rem 3.125rem 1.438rem;
		background: $color-background;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
	}

	&__image {
		max-width: initial;
		width: 100%;
	}

	.buttons {
		margin-top: auto;
	}
}
