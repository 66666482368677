﻿// Merriweather
@font-face {
	font-family: 'AvenirNext';
	src: url('../../fonts/AvenirNext-Medium.woff2') format('woff2'), url('../../fonts/AvenirNext-Medium.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'AvenirNext';
	src: url('../../fonts/AvenirNext-Bold.woff2') format('woff2'), url('../../fonts/AvenirNext-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'AvenirNext';
	src: url('../../fonts/AvenirNext-Heavy.woff2') format('woff2'), url('../../fonts/AvenirNext-Heavy.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@mixin font-text {
	font-family: $base-font-family;
	font-size: 1.125rem;
	line-height: 1.625rem;
}

@mixin font-hero {
	font-weight: bold;
	font-size: 2.25rem;
	line-height: 2rem;
	letter-spacing: -0.032em;

	@include bp-min(s) {
		font-size: 3.375rem;
		line-height: 3rem;
	}

	@include bp-min(m) {
		font-size: 4.375rem;
		line-height: 3.875rem;
	}
}

@mixin font-hero-small {
	font-weight: bold;
	font-size: 1.875rem;
	line-height: 2rem;
	letter-spacing: -0.032em;

	@include bp-min(s) {
		font-size: 3.75rem;
		line-height: 3.875rem;
	}
}

@mixin font-navigation {
	font-size: 1.0625rem;
}

@mixin font-small {
	font-size: 0.875rem;
}

@mixin font-title {
	font-size: 1.75rem;
	line-height: 1.75rem;

	@include bp-min(s) {
		font-size: 2.5rem;
		line-height: 2.5rem;
	}
}

@mixin font-title-larger {
	font-size: 1.75rem;
	line-height: 1.75rem;

	@include bp-min(xs) {
		font-size: 2.5rem;
		line-height: 2.5rem;
	}

	@include bp-min(s) {
		font-size: 3.125rem;
		line-height: 3.125rem;
	}
}

@mixin font-sub-title {
	font-size: 1.75rem;
	line-height: 1.75rem;

	@include bp-min(xs) {
		font-size: 1.875rem;
		line-height: 1.75rem;
	}
}

@mixin font-footer {
	font-size: 1.25rem;
	line-height: 1.25rem;
}

@mixin font-legal {
	font-size: 0.75rem;
	line-height: 1.25rem;
}

@mixin font-overview {
	font-size: 1.5rem;
	line-height: 1.5rem;
}

@mixin font-overview-large {
	font-size: 1.75rem;
	line-height: 1.75rem;

	@include bp-min(xs) {
		font-size: 2.75rem;
		line-height: 2.75rem;
	}
}
