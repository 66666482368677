.text {

	&.-center {
		text-align: center;
	}

	&.-small {
		@include font-small;
	}

	&.-tweet {
		overflow: hidden;
		margin: 0 0 0.813rem;
	}

	p,
	ul,
	ol,
	dl {
		margin: 0 0 1.625rem;

		&:last-child {
			margin: 0 0 1.25rem;
		}
	}

	ul,
	ol {
		@extend .no-bullets;

		li {
			position: relative;
			padding: 0 0 0 1.375rem;

			&:before {
				position: absolute;
				content: '';
				width: 0.375rem;
				height: 0.375rem;
				border-radius: 50%;
				top: 0.625rem;
				left: 0;
				background: $color-text;
			}
		}
	}

	a {
		text-decoration: underline;
		color: $color-secondary;

		&:hover {
			text-decoration: none;
		}
	}
}
