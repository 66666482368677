﻿.project-table {
	margin-top: 12.5rem;
	margin-bottom: 3rem;
	visibility: hidden;

	.no-js & {
		visibility: visible;
	}
}

.project-map {
	position: relative;
	height: 36.625rem;
	padding-top: 9.375rem;
	background: $color-primary;

	@include bp-min(xs) {
		height: 42.875rem
	}

	@include bp-min(s) {
		height: 49.125rem;
		padding: 0;
	}

	@include bp-min(m) {
		height: 55.375rem;
	}

	&:after {
		position: absolute;
		content: '';
		width: 100%;
		height: 12.5rem;
		top: 0;
		left: 0;
		background: linear-gradient(to bottom, rgba(0,59,106,1) 0%,rgba(0,59,106,0) 100%);
	}
}

.project-map-legend {
	background: $color-secondary;
	color: $color-primary;

	&__item {
		position: relative;
		float: left;
		font-weight: bold;
		padding: 0.813rem 0 0.688rem 1.875rem;
		margin: 0 1.875rem 0 0;

		&:before {
			position: absolute;
			content: '';
			width: 1.25rem;
			height: 1.5rem;
			top: 0.813rem;
			left: 0;
		}

		&.-ultra-clima:before {
			background: url(../../images/pointer-ultra-clima.png) no-repeat center;
			background-image: url(../../images/pointer-ultra-clima.svg), none;
		}

		&.-kubo:before {
			background: url(../../images/pointer-kubo.png) no-repeat center;
			background-image: url(../../images/pointer-kubo.svg), none;
		}
	}
}