﻿.content-block {
	position: relative;
	margin-bottom: 5rem;
	z-index: 0;

	.frontpage & {

		&:nth-child(2) {
			margin-top: 5rem;
		}
	}

	&.-text {
		margin-bottom: 3.25rem;
	}

	&.-form {
		background-color: $color-primary;
		color: #fff;
		max-width: rem(1328px);
		padding: 4.75rem 1.25rem 3.313rem;
		margin-right: auto;
		margin-left: auto;
	}

	&.-columns,
	&.-text:nth-of-type(even) {

		.container {
			max-width: rem(1328px);
			padding: 4.75rem 1.25rem 3.313rem;
			background: rgba($color-secondary, 0.15);

			@include bp-min(l) {
				padding: 4.75rem 5.25rem 3.313rem;
			}
		}
	}

	&.-text:nth-of-type(even) {
		margin-bottom: 5rem;

		.user-direction {
			@extend .buttons;
		}
	}

	&.-video,
	&.-columns {
		color: #fff;

		.title {
			color: #fff;
		}
	}

	&.-video {

		.container {
			max-width: 92.375rem;
			height: 26.25rem;

			@include bp-min(s) {
				height: 32.5rem;
			}

			@include bp-min(m) {
				height: 38.75rem;
			}
		}
	}

	&.-overview {
		margin-bottom: 1.25rem;
	}

	&__video {
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	&__background-image {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	.container {
		display: flex;
		flex-wrap: wrap;

		h2 {
			order: -1;
		}

		@include bp-min(s) {
			display: block;
		}
	}

	.user-direction {
		@extend .links;
	}

	&.-center {

		.container {

			> .title,
			> .text {
				text-align: center;
			}
		}
	}

	&.-background {
		margin: 0 0 rem(30px);

		.title {
			margin: 0 0 rem(37px);
		}

		.container {
			position: relative;
			padding-top: rem(50px);
			padding-bottom: rem(50px);
		}

		.row {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}

		.content-container {
			max-width: rem(520px);

			@include bp-min(m) {
				padding: 0 rem(20px) 0 0;
			}
		}
	}
}

.breadcrumbs__wrapper + .content-block {
	margin-top: 2.188rem;
}
