﻿/*------------------------------------*\
	#Responsive nav
\*------------------------------------*/

.responsive-nav {
	position: fixed;
	z-index: 10;
	width: 20rem;
	height: 100%;
	padding: 4.375rem 3.125rem 0;
	top: 0;
	right: 0;
	transition: right 0.3s ease-in-out;
	background: rgba($color-primary, 0.97);
	overflow-x: hidden;
	overflow-y: visible;

	&.-collapsed {
		right: -20rem;
	}

	@include bp-min(xs) {
		width: 22.375rem;

		&.-collapsed {
			right: -22.375rem;
		}
	}

	@include bp-min(m) {
		position: static;
		width: 100%;
		height: auto;
		opacity: 1;
		background: transparent;
		overflow: visible;
		transition: none;
		box-shadow: none;
		padding: 0;

		&.-collapsed {
			left: 0;
			transition: none;
		}

		&.headroom--not-top {
			position: fixed;
		}
	}
}