﻿.masonry {
	margin: rem(-12px);
}

.masonry-item {
	width: (100% / 3);
	border: solid rem(12px) #fff;

	&.-tekst {
		background: $color-primary;
		color: #fff;
		padding: rem(48px) rem(50px) rem(23px);

		.masonry-item__title {
			@include font-overview;
			text-transform: uppercase;
			font-weight: 900;
			margin: 0 0 rem(16px);
		}

		.button {
			margin: 0 0 rem(27px);
		}
	}

	img {
		width: 100%;
		height: auto;
	}
}
