﻿.nav-main {
	padding: 1rem 0 1.375rem;
	overflow-x: hidden;
	overflow-y: auto;

	ul {
		height: 100%;
		padding: 0;
		margin: 0;
		list-style: none;

		li {

			&:last-child a {
				border-bottom: 1px solid $color-secondary-border;
			}

			&:hover,
			&.active {
				background: rgba($color-secondary, 0.1);
			}

			&:hover,
			&.open {

				ul {
					visibility: visible;
					display: block;
					opacity: 1;
				}
			}

			&.sub > a {

				&:after {
					@include icon($icon-arrow-down);
					position: absolute;
					font-size: 0.563rem;
					top: 1rem;
					right: 1.25rem;
				}
			}

			a {
				@include font-navigation;
				position: relative;
				display: block;
				color: #fff;
				text-decoration: none;
				padding: 0.438rem 0.875rem 0.25rem;
				border-top: 1px solid $color-secondary-border;

				&:hover {
					//color: color('secondary');
				}
			}

			ul {
				z-index: 1;
				padding: 0;
				visibility: hidden;
				display: none;
				opacity: 0;

				li {

					&.active a,
					&:hover a {
						background: #fff;
						color: $color-primary;
					}

					&:last-child a {
						border-bottom: none;
					}
				}
			}
		}
	}

	@include bp-min(m) {
		float: right;
		position: absolute;
		z-index: 1;
		padding: 0 3.75rem 0 0;
		top: 3.375rem;
		right: 0;
		overflow: visible;

		> ul {
			float: left;
			height: auto;
			padding: 0;

			> li {
				//background: none;
				float: left;
				position: relative;
				margin: 0 0.5rem;

				&:hover,
				&.open {
					background: $color-menu;

					ul {
						background: $color-menu;
					}
				}

				&:last-child a {
					border-bottom: none;
				}

				&.sub > a:after {
					display: none;
				}

				&.nav-home {
					display: none;
				}

				a {
					padding: 0.75rem 1rem;
					transition: none;
					border: none;
				}

				ul {
					position: absolute;
					height: auto;
					min-width: 10rem;
					padding: 0;

					li {

						a {
							//padding: 1rem 2rem;
							white-space: nowrap;
						}
					}
				}
			}
		}
	}
}
