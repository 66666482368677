﻿.slider {
	position: relative;
	z-index: 0;
	overflow: hidden;
	width: 100%;
	padding: 0;

	@include bp-min(s) {
		padding: 0 6.25rem;
	}
}

.slider-button-next,
.slider-button-prev {
	position: absolute;
	display: none;
	z-index: 100;
	width: 6.25rem;
	height: 100%;
	top: 0;
	cursor: pointer;

	@include bp-min(s) {
		display: block;
	}

	&:before {
		position: absolute;
		margin: -1.469rem 0 0;
		top: 50%;
		font-size: 2.938rem;
		color: $color-secondary;
		transition: all 0.3s ease-in-out;
	}
}

.slider-button-next {
	right: 0;

	&:before {
		@include icon($icon-arrow-right);
		right: 1.688rem;
	}

	&:hover:before {
		right: 1.375rem;
	}
}

.slider-button-prev {
	left: 0;

	&:before {
		@include icon($icon-arrow-left);
		left: 1.688rem;
	}

	&:hover:before {
		left: 1.375rem;
	}
}