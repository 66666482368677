﻿.columns {
	margin-bottom: -1.875rem;

	&.-center {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.column {
	margin-bottom: 1.875rem;
}