﻿.bullet-points {
	background: rgba($color-secondary, 0.15);
	padding: 2.5rem 2.5rem 1.25rem;

	.list {
		list-style: none;
		padding-left: 0;
		border-top: 1px solid rgba($color-primary, 0.1);
		font-size: 1rem;

		li {
			border-bottom: 1px solid rgba($color-primary, 0.1);
			padding: 0.625rem 0 0.625rem 1.25rem;
			position: relative;

			&:before {
				content: '•';
				position: absolute;
				top: 0.75rem;
				left: 0;
				color: $color-primary;
			}
		}
	}
}
