﻿.service-items-container {
	padding: 39px 0 0;
}

.service-items {
	@extend .clearfix;
	display: flex;
	flex-wrap: wrap;
}

.service-item {
	float: left;
	//display: flex;
	//flex-wrap: wrap;
	position: relative;
	width: 100%;
	//padding: 2.938rem 2.5rem 3.125rem;
	background: $color-background;

	&:nth-child(odd) {
		background: $color-background;
	}

	&:nth-child(even) {
		background: $color-secondary-contrast;
	}

	&:last-child:before {
		display: none;
	}

	&__content {
		padding: 0 2.5rem 2.5rem;
	}

	&__sub-title.sub-title {
		position: relative;
		color: $color-primary;
		margin: 0 0 1.625rem;
		padding: 1.6875rem 2.5rem 0;
		cursor: pointer;

		&:after {
			@include icon($icon-arrow-bold-down);
			position: absolute;
			font-size: 0.8125rem;
			top: 2.25rem;
			right: 2.5rem;
			color: $color-primary;
		}

		&[aria-expanded=true] {

			&:after {
				@include icon($icon-arrow-bold-up);
			}
		}
	}

	&__image-container {
		margin: -0.438rem 0 1.875rem;

		@include bp-min(s) {
			float: right;
		}

		@include bp-min(m) {
			float: none;
		}

		@include bp-min(l) {
			float: right;
		}
	}

	.text {
		@include font-small;
		margin: 0 0 21px;
	}

	.button {
		margin-top: auto;
	}
}