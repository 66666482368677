﻿.overview {

	&.block-row-m-3 {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__column {

		&.-block .overview-item,
		&.-large-block .overview-item {
			height: auto;
			margin: 0 0 3.125rem;
		}

		&.-block .overview-item {
			height: 17.75rem;
		}

		&.-large-block .overview-item {
			height: 17.75rem;

			@include bp-min(s) {
				height: 28.125rem;
			}

			@include bp-min(m) {
				height: 38.625rem;
			}
		}
	}
}

.overview-item {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 0;
	height: 28.125rem;
	padding: 0 1.25rem;
	margin: 0 0 3.75rem;
	text-align: center;
	background: $color-primary;
	color: #fff;

	.block-row-s-2 & {
		height: 23rem;
	}

	.block-row-m-3 & {
		height: 17.75rem;
	}

	&.-no-image {
		background: $color-primary url(../../images/fallback-image.jpg) no-repeat;
		background-size: cover;
	}

	&.-exhibition {
		background: $color-primary url(../../images/background-exhibition.jpg) no-repeat;
		background-size: cover;
	}

	&:hover .overview-item__button {
		@extend .hover;
	}

	&__content {
		max-width: 40.25rem;

		.block-row-m-3 & {
			max-width: 18rem;
		}
	}

	&__title {
		@include font-title-larger;
		display: block;
		text-transform: uppercase;
		font-weight: 900;
		color: #fff;
		margin: 0 0 1.563rem;

		.block-row-s-2:not(.block-row-m-3) & {
			@include font-title;
		}

		.block-row-m-3 & {
			@include font-sub-title;
		}

		.-block & {
			@include font-overview;
		}

		.-large-block & {
			@include font-overview-large;
		}
	}

	h2 {
		@include font-overview;
		font-weight: normal;
		margin: 0;
	}

	&__date {
		display: block;
		@include font-overview;
		font-weight: bold;
	}

	&__small {
		display: block;
		margin: 0 0 0.75rem;
	}

	&__background-image {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}
}
