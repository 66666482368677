﻿.history-block-intro {
	display: flex;
	min-height: 100vh;
	flex-direction: column;

	> .container {
		text-align: center;
		padding-bottom: rem(53px);
	}

	.breadcrumbs {
		margin: 0 0 rem(21px);
	}

	.history-block {
		min-height: initial;
		flex-grow: 1;
	}
}

.history-block {
	position: relative;
	display: flex;
	align-items: center;
	z-index: 0;
	min-height: 100vh;
	padding: rem(50px) 0;

	.anchor {
		top: 0;
	}

	&.-highlight {
		background: #cfab7a;

		.history-block__content {
			color: #fff;
			background: rgba(#cfab7a, 0.8);

			@include bp-min(m) {
				background: none;
			}

			.title,
			.history-block__subtitle {
				color: #fff;
			}
		}

		.history-block__image.-half {

			@include bp-min(m) {
				width: 50%;
				right: 0;
			}
		}

		&:nth-child(odd),
		&:nth-child(even) {

			.history-block__content:before {
				display: none;
			}
		}

		&:nth-child(odd) {

			.history-block__image.-half {

				@include bp-min(m) {
					right: 0;
					left: auto;
				}
			}
		}
	}

	.row {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		@include bp-min(m) {
			flex-wrap: none;
		}

		.col:last-child {

			img {
				margin-top: rem(30px);

				@include bp-min(m) {
					margin-top: auto;
				}
			}
		}
	}

	.history-block__image {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	.history-block__content {
		position: relative;
		background: #fff;
		width: calc(100% - 50px);
		padding: rem(45px) rem(50px) rem(43px);
		margin: 0 0 0 auto;

		@include bp-min(m) {
			margin: initial;
		}

		&:before {
			position: absolute;
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			top: 50%;
			transform: translateY(-50%);
			display: none;

			@include bp-min(m) {
				display: block;
			}
		}
	}

	.history-block__subtitle {
		@include font-overview;
		text-transform: uppercase;
		font-weight: 900;
		margin: 0 0 rem(6px);
		color: $color-secondary;
	}

	.history-block__year {
		@include font-overview;
		z-index: 2;
		position: absolute;
		display: inline-block;
		padding: rem(14px) rem(20px) rem(12px);
		top: 0;
		left: 50%;
		transform: translate(-50%, -100%);
		font-weight: 900;
		text-transform: uppercase;
		color: #fff;
		background: $color-secondary;
		transition: background 0.3s ease-in-out;

		&:after {
			@include icon($icon-arrow-xbold-down);
			position: relative;
			font-size: rem(13px);
			margin-left: rem(10px);
		}

		&:hover {
			background: lighten($color-secondary, 15%);
		}
	}

	.video {
		position: relative;
		display: block;

		&:after {
			@include icon($icon-play);
			position: absolute;
			width: 4.688rem;
			height: 4.688rem;
			padding: 1.125rem 0 0 0.375rem;
			border-radius: 50%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			background: $color-secondary;
			font-size: 2.313rem;
			color: #fff;
			transition: background 0.3s ease-in-out;
			text-align: center;
		}

		&:hover {

			&:after {
				background: $color-primary;
			}
		}
	}

	&:before {
		position: absolute;
		z-index: 1;
		content: '';
		width: rem(40px);
		height: rem(40px);
		margin: rem(-20px) 0 0 0;
		border: rem(11px) solid $color-secondary;
		top: 50%;
		left: rem(2.5px);
		background: #fff;
		border-radius: 50%;

		@include bp-min(m) {
			margin: rem(-20px) 0 0 rem(-20px);
			left: 50%;
		}
	}

	&:after {
		position: absolute;
		content: '';
		width: rem(6px);
		height: 100%;
		top: 0;
		left: rem(20px);
		background: url(../../images/dotted-line-white.svg) 0 -3px;

		@include bp-min(m) {
			left: 50%;
			margin: 0 0 0 rem(-3px);
		}
	}

	&.-blue-dots:after {
		background: url(../../images/dotted-line-blue.svg) 0 -3px;
	}

	&:first-of-type {

		&:after {
			top: 50%;
			height: 50%;
		}

		.history-block__year {
			display: none;
		}
	}

	&.-white {

		.history-block__content {
			background: #e1eff9;
		}

		&:nth-child(odd) {

			.history-block__content:before {
				border-color: transparent transparent transparent #e1eff9;
			}
		}

		&:nth-child(even) {

			.history-block__content:before {
				border-color: transparent #e1eff9 transparent transparent;
			}
		}
	}

	&:nth-child(odd) {

		.col:last-child {
			padding: 0 0 0 rem(50px);
		}

		.history-block__content {

			&:before {
				border-width: rem(35px) 0 rem(35px) rem(32px);
				border-color: transparent transparent transparent #fff;
				right: rem(-32px);
			}
		}

		img {
			display: block;

			@include bp-min(m) {
				flex-wrap: none;
			}
		}

		.video {
			margin: 0 0 0 rem(50px);

			img {
				margin: 0;
			}
		}
	}

	&:nth-child(even) {

		.col:first-child {

			@include bp-min(m) {
				order: 1;
			}
		}

		.col:last-child {
			padding: 0 0 0 rem(50px);

			@include bp-min(m) {
				padding: 0 rem(50px) 0 0;
			}
		}

		.history-block__content {
			margin: 0 0 0 rem(50px);

			&:before {
				border-width: 35px 32px 35px 0;
				border-color: transparent #fff transparent transparent;
				left: rem(-32px);
			}
		}
	}
}
