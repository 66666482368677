﻿.logo {
	overflow: hidden;
	position: absolute;
	z-index: 1;
	display: block;
	width: 11.5rem;
	height: 6.25rem;
	top: 2.5rem;
	left: 50%;
	text-indent: -9999px;
	background: url(../../images/logo-kubo.png) no-repeat left center;
	background-image: url(../../images/logo-kubo.svg), none;
	transform: translateX(-50%);

	@include bp-min(m) {
		left: 1.25rem;
		transform: none;
	}
}
