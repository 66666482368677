﻿@import "~toastr/toastr.scss";

#toast-container {
	top: 135px;
	right: 40px;

	.toast {
		padding: 0;
		margin: 0;
		opacity: 1;
		width: 326px;

		&__content {
			background: #fff;
			color: $color-primary;
			font-size: 18px;

			h2 {
				@include font-footer;
				padding: 13px 50px 13px 76px;
				margin: 0;
				background: $color-banana;
				text-transform: uppercase;
				font-weight: bold;
				color: $color-primary;
				font-size: 18px;

				&:before {
					@include icon($icon-calender);
					position: relative;
					font-size: 16px;
					top: 1px;
					right: 10px;
				}
			}

			p {
				padding: 13px 40px 25px 50px;
				margin: 0;

				strong {
					display: block;
					margin: 0 0 11px;
				}
			}
		}
	}

	.toast-progress {
		background: $color-primary;
		opacity: 0.5;
	}

	.toast-close-button {
		position: absolute;
		top: 0;
		right: 18px;
		color: $color-primary;
	}
}