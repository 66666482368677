﻿.project-label {
	@extend .clearfix;
	display: flex;
	flex-wrap: wrap;
	background: rgba($color-secondary, 0.15);

	.frontpage & {
		margin: 3.25rem 0 3.125rem;
	}

	&__content {
		padding: 3.125rem 1.25rem 2.313rem;

		@include bp-min(m) {
			float: right;
			width: 50%;
			min-height: 25rem;
			padding: 3.125rem 3.125rem 2.313rem;
			order: 1;
		}
	}

	&__title {
		width: 100%;

		&.-ultra-clima,
		&.-venlogreenhouse,
		&.-solar-clima,
		&.-urban-clima {
			position: relative;
			height: 40px;
			text-indent: -9999px;
			overflow: hidden;

			&:before {
				position: absolute;
				content: '';
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}

		&.-ultra-clima:before {
			background: url(../../images/project-labels/ultra-clima.png) no-repeat left top;
			background-image: url(../../images/project-labels/ultra-clima.svg), none;
		}

		&.-venlogreenhouse:before {
			background: url(../../images/project-labels/venlogreenhouse.png) no-repeat left top;
			background-image: url(../../images/project-labels/venlogreenhouse.svg), none;
		}

		&.-solar-clima:before {
			background: url(../../images/project-labels/solar-clima.png) no-repeat left top;
			background-image: url(../../images/project-labels/solar-clima.svg), none;
		}

		&.-urban-clima:before {
			background: url(../../images/project-labels/urban-clima.png) no-repeat left top;
			background-image: url(../../images/project-labels/urban-clima.svg), none;
		}
	}

	&__image-container {
		position: relative;
		width: 100%;
		min-height: 12.5rem;

		@include bp-min(m) {
			float: left;
			width: 50%;
			min-height: 25rem;
		}
	}

	&__image {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}
}
