﻿.banner {
	width: 100%;
	padding: 1rem 0;
	background: $color-banana;
	text-align: center;

	+ .content-block {
		margin-top: 5rem;
	}

	@include bp-min(m) {
		padding: 2.375rem 0;
	}

	&.banner-hillenraad {
		background: #cfab7a;
		color: #fff;

		h2 {
			padding-top: 6.25rem;
			position: relative;

			@include bp-min(m) {
				padding-top: 0;
				padding-left: 6.25rem;
			}
		}

		h2:before {
			content: '';
			display: block;
			background: url(../../images/hillenraad-logo.svg) no-repeat 0 50%;
			background-size: 100% auto;
			width: 70px;
			height: 80px;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);

			@include bp-min(m) {
				top: 50%;
				left: 0;
				transform: translateY(-50%);
			}
		}
	}

	h2,
	.button {
		display: inline-block;
		margin: 0.625rem;
		vertical-align: middle;
	}
}
