﻿.container {
	padding: 0 1.25rem;
	width: 100%;

	&.-relative {
		position: relative;
	}

	&.-small {
		max-width: rem(1140);
	}

	&.-med {
		max-width: rem(1350px);
	}

	&.-larger {
		max-width: rem(1518px);
	}
}
