﻿.modal-overlay {
	background: rgba($color-primary, 0.9);
	padding: 0 1rem;

	& > .modal-close {
		display: none;
	}
}


.modal {
	background: #fff;
	position: relative;
	max-width: 900px;

	.modal-content {
		font-size: 1rem;
		padding-top: 3.125rem;

		@include bp-min(m) {
			padding: 3.125rem;
		}

		strong {
			font-size: 1.125rem;
		}

		a:not(.button) {
			color: $color-secondary;
		}
	}
}

button.modal-close {
	position: absolute;
	top: 1rem;
	right: 1rem;
	width: 2rem;
	height: 2rem;
	min-height: initial;
	padding: 0;
	border-radius: 50%;
	font-size: 0;
	color: $color-primary;
	transition: all 0.3s ease-in-out;

	&:before {
		@include icon($icon-close);
		position: absolute;
		font-size: 1rem;
		top: .5rem;
		left: .5rem;
	}

	&:hover {
		color: $color-secondary;
	}
}