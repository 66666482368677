﻿//@import "~lightgallery/dist/css/lightgallery.css";

[rel=lightbox][data-html] {
	cursor: pointer;
}

.image-gallery {
	margin-bottom: 4.813rem;

	@include bp-min(m) {
		margin-bottom: 5.75rem;
	}

	&.-gutter-2 {
		margin-right: -1px;
		margin-left: -1px;
	}
}

.image-gallery__big {
	@include responsive-container-psudo(900, 600);
	margin-bottom: 2.5rem;
	min-height: 18.75rem;

	img {
		width: 100%;
		max-width: initial;
		min-height: 18.75rem;
		object-fit: cover;
		object-position: center;
	}
}

.gallery-item {
	@include responsive-container-psudo(600, 400);
	cursor: pointer;
	display: block;
	background-size: 33% auto;
	margin: 0 0 3px;
	border-right-width: 2px;
	border-left-width: 2px;


	&:after {
		opacity: 0;
		background: $color-primary;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		content: '';
		transition: opacity 0.25s ease-in-out;
	}

	&.-empty {
		cursor: default;

		&:before {
			background: rgba($color-secondary, 0.15);
		}

		&:after {
			content: none;
		}
	}

	&:hover {

		&:after {
			opacity: 0.8;
		}
	}
}
