﻿.button {
	display: inline-block;
	letter-spacing: 0;
	position: relative;
	z-index: 0;
	color: $color-primary;
	overflow: hidden;
	min-height: 0; // Using line-height instead
	padding: 0.563rem 3rem 0.625rem 1.25rem;
	background: $color-banana;
	vertical-align: middle;
	transition: all 0.2s ease-out;
	transform: perspective(1px) translateZ(0); // Improve performance on mobile/tablet devices // Perspective reduces blurryness of text in Chrome
	box-shadow: 0 0 1px rgba(0, 0, 0, 0); // Improve aliasing on mobile/tablet devices
	&:after {
		@include icon($icon-arrow-bold-right);
		position: absolute;
		margin: -0.375rem 0 0;
		top: 50%;
		right: 1.25rem;
		font-size: 0.75rem;
	}

	&:hover,
	&:focus,
	&:active,
	&.hover {
		background: #fff;
		color: $color-secondary;
	}

	&.-white {
		background: #fff;
		color: $color-primary;

		&:hover,
		&:focus,
		&:active,
		&.hover {
			background: #fff;
			color: $color-secondary;
		}
	}

	&.-primary {
		background: $color-primary;
		color: #fff;

		&:hover,
		&:focus,
		&:active,
		&.hover {
			background: $color-banana;
			color: $color-primary;
		}
	}

	&.-pylot {
		background: $color-pylot;
		color: #fff;

		&:hover,
		&:focus,
		&:active,
		&.hover {
			background: #fff;
			color: $color-pylot;
		}
	}

	&.-full-width {
		width: 100%;
	}

	&.-twitter {
		position: relative;
		padding-left: 3.438rem;

		&:before {
			@include icon($icon-twitter);
			position: absolute;
			font-size: 1.625rem;
			top: 0.688rem;
			left: 1.25rem;
			color: #69addf;
		}
	}
}

.buttons {
	margin: 0 0 0;
	width: 100%;

	.content-block & {
		margin-top: 2rem;
		margin-bottom: 1.688rem;
	}

	&.-center {
		text-align: center;
	}

	&.-overview {
		margin: 0;
	}

	a {
		@extend .button;
	}
}