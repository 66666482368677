﻿.form {

	label {
		margin: 0 0 0.813rem;
	}

	textarea {
		min-height: 8.438rem;
		margin: 0 0 2.125rem;
	}

	.checkbox label,
	.dataconsent label {
		margin: 0 0 1.625rem;
	}

	.form-actions {
		padding: 0;
		margin: 0 0 2.063rem;
	}

	a {
		color: $color-secondary;
	}

	&__wrapper {
		position: relative;
		z-index: 0;
		background: $color-primary;
		color: #fff;
		padding: 4.75rem 1.25rem 3.25rem;
		margin: 0 0 5rem;

		@include bp-min(xs) {
			padding: 4.75rem 3.125rem 3.25rem;
		}

		.title {
			color: #fff;
		}
	}

	&__background-image {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	fieldset:last-of-type {
		display: none;
	}

	.submitButtonClass button {
		@extend .button;
	}
}

// CUSTOM OVERRIDE Landing page form
.two-column-form {
	@include bp-min(s) {
		.form-actions {
			float: right;
			margin-top: -5rem;
		}

		div.text:last-of-type {
			width: 50%;
			float: right;
			padding-left: ($grid-column-gutter / 2);
			margin-top: -1rem;
		}
	}
}