﻿.title {
	@include font-title;
	color: $color-primary;
	font-weight: 900;
	text-transform: uppercase;
	margin: 0 0 1.5rem;

	&.-limited-width {
		max-width: 37.25rem;
	}

	+ .columns {
		margin-top: 1.813rem;
	}

	&.-shadow {
		text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
	}

	&.-center {
		text-align: center;
		max-width: initial;
	}

	&.-call-to-action {
		margin: 0 0 2.125rem;
	}

	&.-white {
		color: #fff;
	}

	&.-pylot {
		color: $color-pylot;
	}

	&.-small {
		font-size: 1.875rem;
	}

	&.-h3 {
		@include font-overview;
		margin: 0;
	}

	&.-h4 {
		@include font-text;
		font-weight: 800;
		text-transform: uppercase;
		margin: 0;
	}

	&.-video {
		position: relative;
		z-index: 1;
		padding: 0 1.25rem 6.563rem;
		margin: 5rem 0 0;
		color: #fff;

		@include bp-min(s) {
			margin: 10rem 0 0;
		}

		&:after {
			@include icon($icon-play);
			position: absolute;
			width: 4.688rem;
			height: 4.688rem;
			padding: 1.125rem 0 0 0.375rem;
			border-radius: 50%;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
			background: $color-secondary;
			font-size: 2.313rem;
			color: #fff;
			transition: background 0.3s ease-in-out;
		}

		.content-block__video:hover & {

			&:after {
				background: $color-primary;
			}
		}
	}
}

.sub-title {
	@include font-sub-title;
	font-weight: 900;
	text-transform: uppercase;
	margin: 0 0 0.188rem;
}

.pre-title {
	display: block;
	color: $color-secondary;
	margin: 0 0 0.5rem;
}